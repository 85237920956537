@import "../../styles/mixins";

%listings {
  width: 100%;
  flex-grow: 1;
  padding: 10px;
  margin-bottom: 50px;
  gap: 20px;
}

.search {
  @media only screen and (max-width: 600px) {
    & {
      position: relative;
      top: 70px;
    }
  }
}

.container {
  width: 100%;
  min-height: 90vh;
  background-color: white;
  @include flex($direction: column, $justify: flex-start);
  overflow-y: auto;
  overflow-x: hidden;
}

.jumbotron {
  width: 100%;
  height: 75vh;
  position: relative;
  @include background-image("../../assets/images/background-image.webp");
  background-size: cover;
  @media only screen and (max-width: 600px) {
    & {
      height: 65vh;
    }
  }
}

.content {
  width: 100%;
  padding: 10px;
  gap: 30px;
  margin-bottom: 40px;
  position: relative;

  @include flex($direction: column, $align: flex-start, $justify: center);
  flex-wrap: wrap;
}

.section {
  width: 100%;
  background-color: white;
  position: relative;
}

.divider {
  position: absolute;
  // top: -180px !important;
  margin-bottom: auto;
  bottom: 99.9% !important;

  @media only screen and (max-width: 600px) {
    svg {
      height: 70px;
    }
  }
}

.more {
  width: 50%;
  height: 50px;
  @include flex();
  margin: 25px auto;
  gap: 10px;
  transition: all 0.5s ease;
  cursor: pointer;

  &:hover {
    scale: 1.05;
  }
}

.link {
  font-size: 18px;
}

.title {
  margin: auto;
  font-size: 24px;

  @media only screen and (max-width: 600px) {
    & {
      font-size: 18px;
    }
  }
}

.jobListings {
  @extend %listings;
  @include flex($justify: center, $align: flex-start);
  flex-wrap: wrap;
  gap: 25px;
}

.publicContent {
  width: 100%;
  min-height: 100vh;
  background: var(--primary-80);
  @include flex($direction: column, $justify: flex-start);
  margin-bottom: 40px;
  padding: 20px;
}

.publicListings {
  margin-top: 20px;
  @extend %listings;
  @include flex($direction: column, $justify: flex-start, $align: center);
}

.imgReveal {
  @include imgReveal();
}

.footer {
  width: 100%;
  height: 60vh;
  background-color: white;
  position: relative;

  margin-bottom: 20px;
  @include flex();
  align-items: center;
}

.dividerRotated {
  position: absolute;
  bottom: -150px;

  svg {
    transform: rotate(180deg);
  }
  z-index: 50;
}
