@import "../../styles/mixins";

.premiumArticleCard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 10px auto;
  cursor: pointer;

  background-color: white;
  border: 2px solid #dbb42c;
  border-radius: 30px;

  padding: 20px;
  gap: 3px;

  height: 135px;
  min-height: 135px;
  width: 290px;
  text-decoration: none;

  .title {
    width: 100%;
    font-size: 15px;
    text-align: center;
    max-height: 40px;
    margin: 0;

    display: -webkit-box;
    -webkit-line-clamp:4 ;
    -webkit-box-orient: vertical;
    overflow: hidden;

    color: black;
  }

  .tag {
    position: absolute;
    right: 0px;
    top: -10px;
    height: fit-content;
    padding: 5px 8px;

    display: flex;
    align-items: center;
    gap: 2px;

    background-color: #efe2b3;
    border-radius: 10px;
    border: 1px solid #eac43a;

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    &__text {
      font-size: 12px;
      margin: 0 !important;
      color: #dbb42c;
    }
  }

  
}
