@import "../../styles/mixins";

%wrapper {
  height: 100%;
  @include flex();
  justify-content: space-between;
  margin: 0 10px;
  padding-right: 10px;
}

.container {
  width: 70%;
  min-height: 70px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  margin: auto;
  position: relative;
  top: 17vh;
  border-radius: 70px;
  transition: all 0.5s ease;
  @include flex();
  justify-content: space-between;
  flex-wrap: wrap;

  @media only screen and (max-width: 1200px) {
    & {
      width: 80%;
    }

    .search {
      width: 40%;
    }

    .location {
      width: 30%;
    }

    .action {
      width: 20%;
    }

    .input {
      font-size: 14px;
    }

    .clear {
      min-width: 10px !important;
      height: 10px !important;
    }
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    background-color: transparent;
    box-shadow: none;
    gap: 10px;
    top: 17vh;

    %small-wrapper {
      width: 100%;
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
        rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
      border-radius: 70px;
      height: 50px;
    }

    .location {
      @extend %small-wrapper;
    }

    .search {
      @extend %small-wrapper;
    }

    .action {
      width: 50%;
    }

    .input {
      font-size: 15px;
    }

    .clear {
      min-width: 10px;
      height: 10px;
    }
  }
}

.search {
  @extend %wrapper;
  width: 45%;
  border-right: 1px solid rgba(0, 0, 0, 0.09);
}

.location {
  @extend %wrapper;
  width: 35%;
}

.action {
  width: 15%;
  height: 100%;
  padding-right: 10px;
  @include flex();
}

.button {
  height: 55px;
}

.input {
  height: 40px;
  width: 100%;
  border: none;
  @include flex();
  flex-direction: space-between;
}

.clear {
  min-width: 12px;
  height: 12px;
  margin-right: 5px;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  @include background-image("../../assets/icons/clear.svg");
}

.show {
  opacity: 1;
}

.hide {
  opacity: 0;
}
