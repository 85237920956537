.premiumTag {
  position: absolute;
  right: 5px;
  top: -7px;
  height: fit-content;

  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;

  background-color: #efe2b3;
  border-radius: 10px;
  border: 1px solid #eac43a;

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  &__text {
    font-size: 12px;
    margin: 0 !important;
    color: #dbb42c;
  }
}
