@import "../../../../styles/_mixins";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #182b46;
  margin-top: 20px;
}

.form {
  width: 70%;
  padding: 30px 50px;

  background-color: white;
  border-radius: 15px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

  @include flex($justify: center, $align: flex-start);
  gap: 50px;
  flex-wrap: wrap;

  flex-grow: 1;

  @include flex($direction: column);
  gap: 15px;

  .upload {
    width: 100%;
    padding: 10px 0;
    min-height: 150px;
    border: 1px dashed #00aadc;
    @include flex($direction: column);
    border-radius: 5px;
  }

  .topInputs {
    margin-top: 10px;
    width: 100%;
    @include flex($align: flex-start, $justify: flex-start);
    flex-wrap: wrap;
    gap: 15px;

    .input {
      width: 45%;
      margin: none;
    }
  }
}
