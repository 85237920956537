@import "../../styles/_mixins.scss";

.container {
  width: 100%;
  height: 80px;
  @include flex($justify: space-between);
  padding: 10px 40px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  z-index: 100;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  @media only screen and (max-width: 1200px) {
    padding: 10px 20px;
  }

  @media only screen and (max-width: 768px) {
    padding: 10px 20px;
    justify-content: space-around;
  }
}

.logo {
  height: 100%;
  width: 300px;
  @include background-image("../../assets/logos/logo.png");
  cursor: pointer;
}

.nav {
  height: 100%;
  flex-grow: 1;
  width: 100%;
  max-width: 1200px;

  z-index: 100;
  @include flex($justify: center);
  gap: 4%;
  font-weight: 500;

  @media only screen and (max-width: 1100px) {
    & {
      display: none;
      position: fixed;
      top: -200%;
      right: -100%;
      gap: 0;
      @include flex($direction: column);
      background-color: white;
      width: 100%;
      height: 100vh;
      transition: 0.3s;
      z-index: 100;

      .link {
        color: #000;
      }
    }

    &.active {
      top: 80px;
      margin: 0;
      right: 0;
      padding-top: 20px;
      @include flex($direction: column, $justify: flex-start);
      gap: 10px;
    }
  }
}

.action {
  height: 100%;
  width: 210px;
  margin-left: 10px;
  @include flex();

  @media only screen and (max-width: 1100px) {
    margin-left: auto;
    margin-right: 20px;
  }

  @media only screen and (max-width: 768px) {
    & {
      height: 30px;
      margin-left: auto;
    }
  }
}

.button {
  @media only screen and (max-width: 768px) {
    & {
      height: 45px;
    }
  }
}

.hamburger {
  display: none;
  cursor: pointer;

  @media only screen and (max-width: 1100px) {
    & {
      display: block;
    }

    &.active .bar:nth-child(2) {
      opacity: 0;
    }
    &.active .bar:nth-child(1) {
      transform: translateY(8px) rotate(45deg);
    }
    &.active .bar:nth-child(3) {
      transform: translateY(-8px) rotate(-45deg);
    }
  }
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background: var(--primary);
  border-radius: 20px;
}

.active {
  display: block;
  .bar:nth-child(2) {
    opacity: 0;
  }
  &.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  &.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
}
