@import "../../styles/mixins";

.share {
  height: 100px;
  width: 50%;
  margin-left: auto;

  @include flex($direction: column, $align: flex-end);

  &__text {
    width: 100%;
    text-align: right !important;
    color: var(--text-color);
  }

  a {
    svg {
      transition: all 0.5s ease;
    }

    &:hover {
      svg {
        fill: var(--primary);
        scale: 1.1;
      }
    }

    .viber {
      width: 26px;
      height: 26px;
      color: black;

      &:hover {
        color: var(--primary);
      }
    }
  }

  .socials {
    display: flex;
    gap: 20px;
  }
}
