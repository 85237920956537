@import "../../../styles/mixins";

.container {
    width: 40%;
    padding: 30px 64px;
  
    h1 {
      color: var(--text-color);
      text-align: left;
      font-size: 32px;
      width: 100%;
      background: var(--gradient-to-right);
  
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  
    @media only screen and (max-width: 800px) {
      & {
        padding: 10px 20px;
        width: 90%;
      }
  
      h1 {
        font-size: 28px;
      }
  
      .form {
        padding: 0;
        flex-direction: column;
        justify-content: flex-start;
      }
  
      .input {
        width: 100% !important;
      }
    }

    .form {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        flex-direction: column;
    }
}
