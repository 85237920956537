@import "../../styles/mixins";

.container {
  width: 100%;
  @include flex($direction: column);
  gap: 50px;
  position: relative;

  h1 {
    font-family: "Playfair Display";
    font-size: 36px;
    margin: 5px 0;

    color: #2a3749;
  }

  & p {
    // text-align: justify;
  }
}

.aboutUs {
  width: 100%;
  padding-top: 20px;
  margin-bottom: 20px;
  @include flex($direction: column, $justify: flex-start);
  position: relative;
  z-index: 10;
  background: linear-gradient(to bottom, #e2e8fd80, white);

  h1 {
    margin: 20px 0;
  }

  p {
    width: 70%;
  }
}

.cards {
  width: 100%;
  min-height: 80vh;
  @include flex($justify: center);
  position: relative;
  flex-wrap: wrap;
  gap: 50px;
  z-index: 10;

  .card {
    width: 80%;
    z-index: 10;
    border-radius: 30px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
      rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;

    padding: 50px 30px;
    @include flex($direction: column, $align: flex-start, $justify: flex-start);
    gap: 30px;
    transition: all 0.5s ease;

    &:hover {
      box-shadow: rgba($color: #f01d2673, $alpha: 0.3) 6px 2px 16px 0px,
        rgba($color: #006cb9, $alpha: 0.4) -6px -2px 16px 0px;
    }

    .tag {
      width: 100%;
      text-transform: uppercase;
      padding-top: 5px;
      font-weight: 500;
      letter-spacing: 0.5px;
    }

    h1 {
      font-size: 30px;
      text-align: center;
      margin: 0 auto;
    }

    @media only screen and (max-width: 800px) {
      width: 90%;
      min-height: 200px;

      .card {
        min-height: 300px;
      }
    }
  }
}

.services {
  width: 100%;
  min-height: 70vh;
  @include flex();
  flex-wrap: wrap;
  padding: 5px;

  .servicesText {
    width: 40%;
    height: 80%;
    @include flex($direction: column, $align: flex-start, $justify: flex-start);
    gap: 10px;
    padding: 3%;
  }

  .image {
    @include background-image("../../assets/images/recruitment.webp");
    width: 50%;
    min-height: 70vh;
  }

  @media only screen and (max-width: 800px) {
    & {
      @include flex($direction: column, $justify: flex-start);
      .servicesText {
        width: 100%;
      }

      .image {
        width: 100%;
        min-height: 400px;
        background-position: center;
        background-size: cover;
      }
    }
  }
}

.viber {
  width: 80%;
  min-height: 45vh;
  position: relative;
  background: var(--viber-gradient);
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  border-radius: 50px;
  padding: 30px 20px;

  @include flex($direction: column);
  gap: 20px;

  h1 {
    color: white;
    font-family: "Open Sans";
    text-align: center;
    z-index: 5;
  }

  p {
    width: 60%;
    color: #dfe5fd;
    z-index: 5;
  }

  .viberBackground {
    margin-right: 20px;
    position: absolute;
    right: 0;
    bottom: 0;
    @include background-image("../../assets/icons/viber.png");
    background-position: right;
    height: 90%;
    opacity: 0.15;
    width: 40%;
  }

  @media only screen and (max-width: 700px) {
    width: 95%;

    p {
      width: 85%;
    }
  }
}

.contact {
  width: 100%;
  min-height: 55vh;
  position: relative;
  margin-top: 20px;
  gap: 10px;

  @include flex($direction: column, $justify: center);

  .contacts {
    width: 90%;
    min-height: 40vh;
    @include flex();
    gap: 10%;

    @media only screen and (max-width: 800px) {
      flex-direction: column;
      margin-top: 30px;
      .contactItem {
        width: 70%;
      }
    }
  }

  p {
    color: #2a3749;
    margin-top: 20px;
    font-size: 18px;
    font-weight: 500;
  }

  .map {
    width: 80%;
    height: 600px;
    cursor: pointer;
    background-image: url("../../assets/images/map.webp");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    transition: all 0.5s ease;

    &:hover {
      scale: (1.01);
    }

    @media only screen and (max-width: 800px) {
      width: 100%;
      height: 300px;
    }
  }
}

.contactItem {
  width: 15%;
  height: 100%;
  min-height: 20vh;
  @include flex($direction: column, $justify: flex-start);

  .icon {
    @include background-image("../../assets/icons/phone.png");
    width: 90%;
    height: 90%;
    min-height: 6vh;
  }

  span {
    width: 60px;
    display: inline-block;
  }

  p {
    color: #2a3749;
    font-weight: bolder;
    text-align: center;
    font-size: 15px;
  }
}

.imgReveal {
  @include imgReveal();
}

.dividerRotated {
  position: absolute;
  width: 100%;
  height: 200px;
  top: 100%;
  svg {
    transform: rotate(180deg) !important;
  }
  z-index: 50;
}

.mission {
  border-top: 1px solid var(--red);
  color: var(--red);
}

.vision {
  border-top: 1px solid #006cb9;
  color: var(--dark-blue);
}

.span {
  color: #c4d1ff;
  font-weight: 700;
  margin-right: 5px;
}
