@import "../../styles/mixins";

.container {
  width: 100%;
  background: linear-gradient(to bottom, #00aadc0d, white);

  @include flex($direction: column, $align: flex-start);
  padding: 0 3%;

  gap: 20px;

  .text {
    width: 90%;
    padding-left: 50px;
    margin-top: 10px;

    padding-bottom: 20px;

    .title {
      font-size: 26px;
      margin-bottom: 15px !important;
      font-weight: bolder;
      @media only screen and (max-width: 600px) {
        & {
          text-align: center;
          margin: 0;
          width: 100%;
        }
      }
    }

    .description {
      font-size: 16px;
      margin: 0;
      color: #182b46;

      @media only screen and (max-width: 600px) {
        text-align: center;
      }
    }
  }
}

.content {
  width: 100%;
  padding: 2%;
  @include flex($direction: column, $justify: flex-start, $align: flex-start);
  gap: 20px;

  @media only screen and (max-width: 800px) {
    width: 100%;
    align-items: center;
  }
}
