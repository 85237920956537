@import "../../styles/mixins";

.container {
  width: 100%;
  min-height: 90vh;
  @include flex($direction: column, $justify: flex-start, $align: flex-start);
}

.searchSection {
  background-color: #e2e8fd80;
  width: 100%;
  min-height: 50vh;
  position: relative;

  .searchTitle {
    margin: 0 auto;
    position: relative;
    text-align: center;
    font-size: 26px;
    top: 10vh;
  }

  .searchSubtitle {
    margin: 10px auto;
    width: 30%;
    position: relative;
    text-align: center;
    top: 10vh;
  }

  @media only screen and (max-width: 600px) {
    height: 65vh;

    .searchTitle {
      font-size: 22px;
      top: 5vh;
    }

    .searchSubtitle {
      top: 5vh;
      width: 90%;
    }
  }
}

.search {
  top: 15vh;
  @media only screen and (max-width: 600px) {
    top: 10vh;
  }
}

.content {
  width: 100%;
  @include flex($direction: column, $justify: space-between);
  background-color: #e2e8fd80;
  margin-bottom: 20px;
}

.jobs {
  width: 100%;
  min-height: 100vh;
  flex-grow: 1;
  padding: 10px;
  margin-bottom: 10vh;
  gap: 20px;
  @include flex($justify: center, $align: flex-start);
  flex-wrap: wrap;
  gap: 25px;
}

.imgReveal {
  @include imgReveal();
  position: relative;
}

.dividerRotated {
  position: absolute;
  width: 100%;
  height: 150px;
  top: 0;
  svg {
    transform: rotate(180deg) !important;
  }
  z-index: 50;
}
