.container {
  display: flex;
  width: 350px;
  border: 1px solid #8892b5;
  border-radius: 4px;
  align-items: center;
}

.input {
  width: 100%;
  height: 100%;
  padding-left: 55px;
  background: #ffffff;
  border: none;

  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: var(--text-color);

  &:focus {
    outline: none !important;
    border-color: rgba(0, 0, 0, 0.65);
  }
}

::placeholder {
  color: #8892b5;
}
