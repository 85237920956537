.file {
  width: 100px;
  position: relative;
  cursor: pointer;

  img {
    width: 90%;
    object-fit: contain;
    object-position: center;
  }

  p {
    text-align: center;
    margin: 0;
    width: 100%;
  }

  .close {
    transition: all 0.5s ease;
    opacity: 0;
    position: absolute;
    right: 10px;
    width: 25px;
    height: 25px;
  }

  &:hover {
    .close {
      opacity: 1;
    }
  }
}
