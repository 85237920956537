.content {
  width: 70%;
  margin: auto;
  display: flex;
  flex-direction: column;
  // margin-top: 10px;

  h1 {
    color: var(--text-color);
    text-align: left;
    font-size: 32px;
    width: 100%;
    background: var(--gradient-to-right);

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  h2 {
    font-size: 17px;
    font-weight: bold;
    margin: 5px 0;
  }

  a {
    text-decoration: none;
    color: var(--primary);
  }
}
