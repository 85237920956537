@import "../../styles/mixins";

.container {
  width: 100%;
  cursor: pointer;
  @include flex($justify: space-around);
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.05);
  animation: 1s alternate 0s 1 slideInFromRight;

  &:hover {
    .icon {
      scale: (1.1);
    }

    .arrow {
      opacity: 0.8;
      scale: 1.1;
      margin-right: 3px;
    }
  }
}

.iconDiv {
  height: 45px;
  min-width: 45px;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  @include flex();
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.icon {
  width: 20px;
  height: 20px;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.text {
  height: 30px;
  flex-grow: 1;
  margin-left: 10px;
  margin-right: 20px;
  @include flex($justify: flex-start);
}

.link {
  text-decoration: none;
  color: black;
}

.arrow {
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  margin-right: 15px;
  width: 15px;
  flex-shrink: 0;
}

@keyframes slideInFromRight {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
