.link {
  text-decoration: none;
  color: black;
  transition: all 0.3s ease;
  text-align: center;

  &:hover {
    color: var(--primary);
  }
}
