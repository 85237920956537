@import "../../styles/mixins";

.container {
  height: 45px;
  border-radius: 30px;
  @include flex();
  cursor: pointer;
  padding: 14px;

  p {
    color: var(--text-color);
    font-size: 14px;
    text-align: center;
    font-weight: 500;
  }
}
