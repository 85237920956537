@import "../../styles/mixins";

.container {
  width: 100%;
  min-height: 90vh;
  @include flex($direction: column, $justify: flex-start, $align: flex-start);
  gap: 30px;
}

.header {
  background-color: #e2e8fd80;
  background: linear-gradient(to right, #3861ed, #00aadc);
  background-image: url("../../assets/images/header.png");
  background-size: cover;
  position: relative;
  width: 100%;
  height: 50vh;

  .title {
    margin: 0 auto;
    position: relative;
    text-align: center;
    font-size: 34px;
    top: 13vh;
    color: white;

    @media only screen and (max-width: 600px) {
      & {
        top: 17vh;
        font-size: 32px;
      }
    }
  }

  .divider {
    position: absolute;
    margin-bottom: auto;
    bottom: -10px;

    @media only screen and (max-width: 600px) {
      svg {
        height: 90px;
      }
    }
  }
}

.content {
  width: 60%;
  margin: 50px auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  @media only screen and (max-width: 800px) {
    width: 90%;
  }
}

.imgReveal {
  @include imgReveal();
  position: relative;
}

.footerDivider {
  position: absolute;
  width: 100%;
  height: 150px;
  top: 0;
  svg {
    transform: rotate(180deg) !important;
  }
  z-index: 50;
}
