@import "../../styles/mixins";

.adminContainer {
  width: 100%;
  height: 100vh;
  display: flex;

  .adminSideMenu {
    height: 100%;
    width: 250px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    align-items: center;
    box-shadow: var(--card-shadow);
    flex-shrink: 0;

    .logo {
      width: 100%;
      cursor: pointer;
      margin: 0 auto;
      object-position: center;
    }

    .navigation {
      padding: 10px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: auto;
      font-size: 18px;

      .link {
        text-decoration: none;
        cursor: pointer;
        width: 70%;

        &:hover {
          color: var(--primary);
        }
      }

      .active {
        color: var(--primary);
        font-weight: bolder;
      }
    }

    .btn {
      margin-top: auto;
      height: 40px;
    }
  }

  .adminContent {
    width: 100%;
    background: var(--primary-80);
  }
}
