@import "../../styles/mixins";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.heading {
  padding-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  .title {
    width: 60%;
    height: 100%;
    @include flex();
  }

  h1 {
    text-align: center;
    color: var(--text-color) c;
    width: 90%;
    margin-bottom: 10px;
  }

  p {
    color: #8892b5;
  }
  background: linear-gradient(to bottom, #00aadc0d, white);
}

.content {
  width: 70%;
  margin: auto;
  font-size: 18px;
  text-align: justify;
  margin-bottom: 10vh;

  p img {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    @media only screen and (max-width: 800px) {
      width: 100% !important;
      height: auto !important;
    }
  }

  iframe {
    margin: 20px auto;
    display: flex;
    justify-content: center;
    width: 70vw !important;
    height: 550px;

    @media only screen and (max-width: 800px) {
      width: 100%;
    }
  }

  @media only screen and (max-width: 800px) {
    width: 80%;
    margin-bottom: 0;
  }
}

.image {
  height: 500px;
  margin: 0 auto;

  position: relative;

  @media only screen and (max-width: 800px) {
    width: 90%;
    height: auto;
    margin-bottom: 0;
  }
}

.imgReveal {
  @include imgReveal();
  position: relative;
}

.dividerRotated {
  position: absolute;
  width: 100%;
  height: 150px;
  top: 0;
  svg {
    transform: rotate(180deg) !important;
  }
  z-index: 50;
}

.expired {
  margin: auto;
  background-color: var(--red);
  padding: 15px;
  color: white;
  border-radius: 10px;
}
