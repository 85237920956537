.blogPost {
  width: 600px;
  max-width: 600px;
  height: 300px;
  display: flex;
  align-items: center;
  background: white;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

  .img {
    height: 100%;
    width: 40%;
    min-width: 40%;
    object-fit: cover;
    border-radius: 8px 0 0 8px;
  }

  .content {
    // flex-grow: 1;
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 10px;
    word-break: break-word;

    .title {
      margin: 0;
      color: var(--text-color);
      text-align: left;
      font-size: 20px;

      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .date {
      color: #8892b5;
    }

    .description {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .actions {
    height: 20px;
    position: relative;
    bottom: 0;
    margin-top: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
