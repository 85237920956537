@mixin flex($direction: row, $justify: center, $align: center) {
  display: flex;
  flex-direction: $direction;
  align-items: $align;
  justify-content: $justify;
}

@mixin background-image($url: "") {
  background-image: url($url);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

@mixin imgReveal($height: 85vh) {
  width: 100%;
  min-height: $height;
  padding-top: 10vh;
  background-color: var(--primary);
  background: linear-gradient(to bottom, #3861ed, #00aadc);

  @include background-image("../../assets/images/Footer2.webp");
  background-size: cover;
  background-attachment: fixed;
  @include flex();
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;

  @media only screen and (max-width: 600px) {
    padding-top: 20vh;
  }
}

.redBlob {
  width: 300px;
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;utf8, %3Csvg width=%22100%25%22 height=%22100%25%22 viewBox=%220 0 1000 1000%22 xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22 %3E %3Cdefs%3E %3Cfilter id=%22grain%22 x=%22-50vw%22 y=%22-50vh%22 width=%22100vw%22 height=%22100vh%22%3E %3CfeFlood flood-color=%22%23ffffff%22 result=%22neutral-gray%22 %2F%3E %3CfeTurbulence in=%22neutral-gray%22 type=%22fractalNoise%22 baseFrequency=%222.5%22 numOctaves=%22100%22 stitchTiles=%22stitch%22 result=%22noise%22 %2F%3E %3CfeColorMatrix in=%22noise%22 type=%22saturate%22 values=%220%22 result=%22destaturatedNoise%22 %3E%3C%2FfeColorMatrix%3E %3CfeComponentTransfer in=%22desaturatedNoise%22 result=%22theNoise%22%3E %3CfeFuncA type=%22table%22 tableValues=%220 0 0.25 0%22%3E%3C%2FfeFuncA%3E %3C%2FfeComponentTransfer%3E %3CfeBlend in=%22SourceGraphic%22 in2=%22theNoise%22 mode=%22soft-light%22 result=%22noisy-image%22 %2F%3E %3C%2Ffilter%3E %3ClinearGradient id=%22linearGradientId%22 gradientTransform=%22rotate(0 0.5 0.5)%22%3E %3Cstop offset=%220%25%22 stop-color=%22%23e2e8fd%22 %2F%3E %3Cstop offset=%22100%25%22 stop-color=%22%23F01D26%22 %2F%3E %3C%2FlinearGradient%3E %3CclipPath id=%22shape%22%3E %3Cpath fill=%22currentColor%22 d=%22M837.5%2C692Q807%2C884%2C604.5%2C907Q402%2C930%2C288%2C793.5Q174%2C657%2C121.5%2C475Q69%2C293%2C240.5%2C203.5Q412%2C114%2C565.5%2C169.5Q719%2C225%2C793.5%2C362.5Q868%2C500%2C837.5%2C692Z%22%3E%3C%2Fpath%3E %3C%2FclipPath%3E %3C%2Fdefs%3E %3Cg filter=%22url(%23grain)%22 clip-path=%22url(%23shape)%22%3E %3Cpath fill=%22url(%23linearGradientId)%22 d=%22M837.5%2C692Q807%2C884%2C604.5%2C907Q402%2C930%2C288%2C793.5Q174%2C657%2C121.5%2C475Q69%2C293%2C240.5%2C203.5Q412%2C114%2C565.5%2C169.5Q719%2C225%2C793.5%2C362.5Q868%2C500%2C837.5%2C692Z%22 %2F%3E %3C%2Fg%3E %3C%2Fsvg%3E");
  position: absolute;
  z-index: -1;
}

.blueBlob {
  width: 200px;
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;utf8, %3Csvg width=%22100%25%22 height=%22100%25%22 viewBox=%220 0 1000 1000%22 xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22 %3E %3Cdefs%3E %3Cfilter id=%22grain%22 x=%22-50vw%22 y=%22-50vh%22 width=%22100vw%22 height=%22100vh%22%3E %3CfeFlood flood-color=%22%23ffffff%22 result=%22neutral-gray%22 %2F%3E %3CfeTurbulence in=%22neutral-gray%22 type=%22fractalNoise%22 baseFrequency=%222.5%22 numOctaves=%22100%22 stitchTiles=%22stitch%22 result=%22noise%22 %2F%3E %3CfeColorMatrix in=%22noise%22 type=%22saturate%22 values=%220%22 result=%22destaturatedNoise%22 %3E%3C%2FfeColorMatrix%3E %3CfeComponentTransfer in=%22desaturatedNoise%22 result=%22theNoise%22%3E %3CfeFuncA type=%22table%22 tableValues=%220 0 0.25 0%22%3E%3C%2FfeFuncA%3E %3C%2FfeComponentTransfer%3E %3CfeBlend in=%22SourceGraphic%22 in2=%22theNoise%22 mode=%22soft-light%22 result=%22noisy-image%22 %2F%3E %3C%2Ffilter%3E %3ClinearGradient id=%22linearGradientId%22 gradientTransform=%22rotate(90 0.5 0.5)%22%3E %3Cstop offset=%220%25%22 stop-color=%22%23006CB9%22 %2F%3E %3Cstop offset=%22100%25%22 stop-color=%22%2300AADC%22 %2F%3E %3C%2FlinearGradient%3E %3CclipPath id=%22shape%22%3E %3Cpath fill=%22currentColor%22 d=%22M830.5%2C690.5Q804%2C881%2C599%2C923Q394%2C965%2C249%2C828Q104%2C691%2C123.5%2C509.5Q143%2C328%2C271%2C193Q399%2C58%2C603%2C86.5Q807%2C115%2C832%2C307.5Q857%2C500%2C830.5%2C690.5Z%22%3E%3C%2Fpath%3E %3C%2FclipPath%3E %3C%2Fdefs%3E %3Cg filter=%22url(%23grain)%22 clip-path=%22url(%23shape)%22%3E %3Cpath fill=%22url(%23linearGradientId)%22 d=%22M830.5%2C690.5Q804%2C881%2C599%2C923Q394%2C965%2C249%2C828Q104%2C691%2C123.5%2C509.5Q143%2C328%2C271%2C193Q399%2C58%2C603%2C86.5Q807%2C115%2C832%2C307.5Q857%2C500%2C830.5%2C690.5Z%22 %2F%3E %3C%2Fg%3E %3C%2Fsvg%3E");
  position: absolute;
  z-index: -1;
}

.yellowBlob {
  width: 300px;
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;utf8, %3Csvg width=%22100%25%22 height=%22100%25%22 viewBox=%220 0 1000 1000%22 xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22 %3E %3Cdefs%3E %3Cfilter id=%22grain%22 x=%22-50vw%22 y=%22-50vh%22 width=%22100vw%22 height=%22100vh%22%3E %3CfeFlood flood-color=%22%23ffffff%22 result=%22neutral-gray%22 %2F%3E %3CfeTurbulence in=%22neutral-gray%22 type=%22fractalNoise%22 baseFrequency=%222.5%22 numOctaves=%22100%22 stitchTiles=%22stitch%22 result=%22noise%22 %2F%3E %3CfeColorMatrix in=%22noise%22 type=%22saturate%22 values=%220%22 result=%22destaturatedNoise%22 %3E%3C%2FfeColorMatrix%3E %3CfeComponentTransfer in=%22desaturatedNoise%22 result=%22theNoise%22%3E %3CfeFuncA type=%22table%22 tableValues=%220 0 0.25 0%22%3E%3C%2FfeFuncA%3E %3C%2FfeComponentTransfer%3E %3CfeBlend in=%22SourceGraphic%22 in2=%22theNoise%22 mode=%22soft-light%22 result=%22noisy-image%22 %2F%3E %3C%2Ffilter%3E %3ClinearGradient id=%22linearGradientId%22 gradientTransform=%22rotate(90 0.5 0.5)%22%3E %3Cstop offset=%220%25%22 stop-color=%22%23F5811D%22 %2F%3E %3Cstop offset=%22100%25%22 stop-color=%22%23FEF200%22 %2F%3E %3C%2FlinearGradient%3E %3CclipPath id=%22shape%22%3E %3Cpath fill=%22currentColor%22 d=%22M902.5%2C631Q812%2C762%2C695.5%2C856Q579%2C950%2C458%2C866Q337%2C782%2C202.5%2C719.5Q68%2C657%2C58.5%2C496.5Q49%2C336%2C197.5%2C284.5Q346%2C233%2C451.5%2C204.5Q557%2C176%2C684.5%2C207.5Q812%2C239%2C902.5%2C369.5Q993%2C500%2C902.5%2C631Z%22%3E%3C%2Fpath%3E %3C%2FclipPath%3E %3C%2Fdefs%3E %3Cg filter=%22url(%23grain)%22 clip-path=%22url(%23shape)%22%3E %3Cpath fill=%22url(%23linearGradientId)%22 d=%22M902.5%2C631Q812%2C762%2C695.5%2C856Q579%2C950%2C458%2C866Q337%2C782%2C202.5%2C719.5Q68%2C657%2C58.5%2C496.5Q49%2C336%2C197.5%2C284.5Q346%2C233%2C451.5%2C204.5Q557%2C176%2C684.5%2C207.5Q812%2C239%2C902.5%2C369.5Q993%2C500%2C902.5%2C631Z%22 %2F%3E %3C%2Fg%3E %3C%2Fsvg%3E");
  position: absolute;
  z-index: -1;
}

.greenBlob {
  width: 200px;
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;utf8, %3Csvg width=%22100%25%22 height=%22100%25%22 viewBox=%220 0 1000 1000%22 xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22 %3E %3Cdefs%3E %3Cfilter id=%22grain%22 x=%22-50vw%22 y=%22-50vh%22 width=%22100vw%22 height=%22100vh%22%3E %3CfeFlood flood-color=%22%23ffffff%22 result=%22neutral-gray%22 %2F%3E %3CfeTurbulence in=%22neutral-gray%22 type=%22fractalNoise%22 baseFrequency=%222.5%22 numOctaves=%22100%22 stitchTiles=%22stitch%22 result=%22noise%22 %2F%3E %3CfeColorMatrix in=%22noise%22 type=%22saturate%22 values=%220%22 result=%22destaturatedNoise%22 %3E%3C%2FfeColorMatrix%3E %3CfeComponentTransfer in=%22desaturatedNoise%22 result=%22theNoise%22%3E %3CfeFuncA type=%22table%22 tableValues=%220 0 0.25 0%22%3E%3C%2FfeFuncA%3E %3C%2FfeComponentTransfer%3E %3CfeBlend in=%22SourceGraphic%22 in2=%22theNoise%22 mode=%22soft-light%22 result=%22noisy-image%22 %2F%3E %3C%2Ffilter%3E %3ClinearGradient id=%22linearGradientId%22 gradientTransform=%22rotate(90 0.5 0.5)%22%3E %3Cstop offset=%220%25%22 stop-color=%22%238EC33B%22 %2F%3E %3Cstop offset=%22100%25%22 stop-color=%22%23c8e2a0%22 %2F%3E %3C%2FlinearGradient%3E %3CclipPath id=%22shape%22%3E %3Cpath fill=%22currentColor%22 d=%22M792.5%2C616Q777%2C732%2C674.5%2C820.5Q572%2C909%2C420%2C906Q268%2C903%2C195%2C770.5Q122%2C638%2C163.5%2C515.5Q205%2C393%2C272%2C307.5Q339%2C222%2C446.5%2C207.5Q554%2C193%2C705.5%2C197Q857%2C201%2C832.5%2C350.5Q808%2C500%2C792.5%2C616Z%22%3E%3C%2Fpath%3E %3C%2FclipPath%3E %3C%2Fdefs%3E %3Cg filter=%22url(%23grain)%22 clip-path=%22url(%23shape)%22%3E %3Cpath fill=%22url(%23linearGradientId)%22 d=%22M792.5%2C616Q777%2C732%2C674.5%2C820.5Q572%2C909%2C420%2C906Q268%2C903%2C195%2C770.5Q122%2C638%2C163.5%2C515.5Q205%2C393%2C272%2C307.5Q339%2C222%2C446.5%2C207.5Q554%2C193%2C705.5%2C197Q857%2C201%2C832.5%2C350.5Q808%2C500%2C792.5%2C616Z%22 %2F%3E %3C%2Fg%3E %3C%2Fsvg%3E");
  position: absolute;
  z-index: -1;
}
