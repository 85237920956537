@import "../../styles/mixins";

.cards {
  width: 100%;
  min-height: 60vh;
  gap: 20px;
  display: flex;
  @include flex();
  flex-wrap: wrap;
  margin-top: 15px;
  margin-bottom: 15vh;
}
