.viber {
  position: fixed;
  padding: 5px;
  bottom: 12%;
  width: 30px;
  z-index: 1000000;
  margin-left: auto;
  text-decoration: none;
  writing-mode: vertical-lr;
  right: 6px;
  color: var(--purple);

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s ease;

  animation: 1s alternate 0s 1 slideInFromRight;

  .text {
    flex-grow: 1;
    font-size: 14px;
    font-weight: 500;
  }

  .icon {
    width: 100%;
    width: 24px;
    transform: rotate(90deg);
  }

  &:hover {
    scale: (1.05);
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
