@import "../../styles/_mixins.scss";

.container {
  width: 70%;
  min-height: 50vh;
  border-radius: 10px;
  margin: 30px 0;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgb(209, 213, 219) 0px 0px 0px 1px inset;

  @include flex($direction: column, $justify: flex-start);
  padding: 30px 64px;

  h1 {
    color: var(--text-color);
    text-align: left;
    font-size: 32px;
    width: 100%;
    background: var(--gradient-to-right);

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media only screen and (max-width: 800px) {
    & {
      padding: 10px 20px;
      width: 90%;
    }

    h1 {
      font-size: 28px;
    }

    .form {
      padding: 0;
      flex-direction: column;
      justify-content: flex-start;
    }

    .input {
      width: 100% !important;
    }
  }
}

.form {
  width: 100%;
  padding: 20px 10px;
  @include flex($justify: center, $align: flex-start);
  gap: 50px;
  flex-wrap: wrap;

  .topInputs {
    margin-top: 10px;
    width: 100%;
    @include flex($align: flex-start, $justify: space-between);
    flex-wrap: wrap;
    gap: 15px;

    .input {
      width: 45%;
      margin: none;
    }
  }
}
