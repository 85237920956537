@import "../../styles/mixins";

.container {
  width: 100%;
  min-height: 90vh;
  background-color: white;
  @include flex($direction: column, $justify: flex-start);
  overflow-y: auto;
  overflow-x: hidden;
}

.header {
  width: 100%;
  min-height: 50vh;
  position: relative;
  @include background-image("../../assets/images/job-img.webp");
  background-size: cover;

  @include flex($direction: column);

  @media only screen and (max-width: 600px) {
    & {
      min-height: 70vh;

      h1 {
        font-size: 28px;
      }

      .title {
        padding-top: 10px;
        width: 80%;
      }

      .subheading {
        width: 80%;
        display: flex;
        align-items: flex-start;
      }
    }

    .back {
      top: 5%;
      left: 5%;
    }
  }
}

.back {
  width: 50px;
  height: 20px;

  position: absolute;
  top: 25%;
  left: 10%;

  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
    transform: rotate(180deg);
    transform: all 0.5s ease;

    &:hover {
      scale: 1.1;
    }

    path {
      fill: white;
    }
  }
}

.subheading {
  width: 50%;
  margin-bottom: 10px;
  @include flex();
  p {
    color: white;
    font-size: 16px;
    text-align: center;
  }
}

.title {
  width: 60%;
  height: 50%;
  min-height: 25vh;

  @include flex($align: flex-start);
  border-top: 1px solid var(--primary);
  padding-top: 42px;

  h1 {
    color: white;
    text-align: center;
    margin: 0;
  }
}

.tag {
  width: 160px;
  height: 50px;
  padding: 5px;
  border-radius: 10px;
  color: white;
  background: var(--red);
  margin-top: 10px;
  @include flex();
}

.infoSection {
  width: 100%;
  min-height: 200px;

  @include flex($direction: column);

  flex-wrap: wrap;
  a {
    width: 200px;
    min-height: 100px;
  }
}

.logo {
  width: 300px;
  margin-bottom: 5px;
  min-height: 100px;
  cursor: pointer;

  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.info {
  width: 80%;
  flex-grow: 1;
  border-bottom: 1px solid #14286c;

  @include flex();
  flex-wrap: wrap;
  gap: 20px;

  .infoWrapper {
    width: 20%;
    min-height: 125px;

    color: var(--primary);
    font-weight: bolder;

    @include flex($direction: column, $justify: flex-start);

    @media only screen and (max-width: 600px) {
      & {
        width: 45%;
      }
    }

    .infoHeading {
      width: 100%;
      @include flex();
      gap: 5px;
      height: 30px;
    }

    .infoText {
      color: var(--text-color);
      text-align: left;
      width: 100%;
      text-align: center;
    }
  }
}

.content {
  width: 80%;
  min-height: 80vh;
  gap: 20px;
  padding-bottom: 50px;
  @include flex($direction: column, $justify: flex-start);

  h1 {
    color: var(--text-color);
    width: 100%;
    font-size: 22px;
  }

  h2 {
    color: #56596a;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 22px;
  }

  p {
    color: #56596a;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    text-align: justify;
  }

  ul {
    color: #56596a;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
  }

  .oglas {
    width: 100%;
  }

  .oglas > img {
    width: 90% !important;
  }
}

.similarJobs {
  width: 100%;
  padding: 10px;
  padding-bottom: 40px;
  min-height: 60vh;
  flex-wrap: wrap;
  background: linear-gradient(to right, #3861ed, #00aadc);

  @include flex();
  gap: 20px;

  h1 {
    width: 100% !important;
  }
}

.note {
  width: 100%;
  position: relative;
  min-height: 50vh;
  @include flex($direction: column);
  gap: 10px;
  padding-bottom: 20px;

  a {
    text-decoration: none;
    color: var(--primary);
  }

  h1 {
    font-size: 24px;
  }

  p {
    width: 50%;
    text-align: center;
    margin: 0;
    @media only screen and (max-width: 700px) {
      & {
        width: 80%;
        font-size: 14px;
      }
    }
  }
}

.imgReveal {
  @include imgReveal();
  background-image: none;
  background: linear-gradient(to top, #3861ed, #00aadc);
}

.dividerRotated {
  position: absolute;
  width: 100%;
  height: 150px;
  top: 100%;
  svg {
    transform: rotate(180deg) !important;
  }
  z-index: 50;
}

.templates {
  width: 100%;
  padding: 30px 0px;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;

  a {
    font-size: 22px;
    color: #182b46;

    text-decoration: none;
    font-weight: 600;
    @include flex();
    gap: 10px;
    transition: all 0.5s ease;

    &:hover {
      color: var(--primary);
    }

    @media only screen and (max-width: 600px) {
      & {
        width: 75%;
        flex-wrap: wrap;
        text-align: center;
      }
    }
  }
}
