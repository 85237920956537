@import "../../../styles/mixins";

.container {
  width: 70%;
  min-height: 85vh;
  border-radius: 10px;
  margin: 30px auto;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgb(209, 213, 219) 0px 0px 0px 1px inset;

  @include flex($direction: column, $justify: flex-start);
  padding: 30px 64px;

  h1 {
    color: var(--text-color);
    text-align: left;
    font-size: 32px;
    width: 100%;
    background: linear-gradient(to right, #3861ed, #00aadc);

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media only screen and (max-width: 800px) {
    & {
      padding: 10px 20px;
      width: 90%;
    }

    h1 {
      font-size: 28px;
    }

    .form {
      padding: 0;
      flex-direction: column;
      justify-content: flex-start;
    }

    .input {
      width: 100% !important;
    }
  }
}

.form {
  width: 100%;
  padding: 20px 10px;
  @include flex($justify: center, $align: flex-start);
  gap: 50px;
  flex-wrap: wrap;

  .topInputs {
    margin-top: 10px;
    width: 100%;
    @include flex($align: flex-start, $justify: space-between);
    flex-wrap: wrap;
    gap: 15px;

    .input {
      width: 45%;
      margin: none;
    }
  }

  .bottomInputs {
    width: 100%;
    flex-grow: 1;

    @include flex($direction: column);
    gap: 15px;

    .upload {
      width: 100%;
      padding: 10px 0;
      height: 150px;
      border: 1px dashed #00aadc;
      @include flex($direction: column);
      background-color: #00a9dc09;
      border-radius: 5px;
      cursor: pointer;
      gap: 5px;

      p {
        margin: 5px auto;
        font-size: 14px;
      }
    }
  }
}

.filename {
  font-size: 21px;
}
