@import "../../styles/mixins";

.container {
  width: 100%;
  background-color: #e2e8fd80;
  min-height: 90vh;
  @include flex($direction: column, $justify: flex-start, $align: flex-start);
  // gap: 25px;
}

.text {
  width: 90%;
  padding-left: 50px;
  padding-bottom: 30px;
  margin-top: 10px;
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
}

.title {
  font-size: 24px;
  margin-bottom: 15px !important;
  font-weight: bolder;
  @media only screen and (max-width: 600px) {
    & {
      text-align: center;
      margin: 0;
      width: 100%;
    }
  }
}

.description {
  font-size: 16px;
  margin: 0;
  color: #182b46;

  @media only screen and (max-width: 600px) {
    text-align: center;
  }
}

.tags {
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
  @include flex();
  gap: 20px;
  flex-wrap: wrap;

  @media only screen and (max-width: 600px) {
    & {
      justify-content: center;
      width: 100%;
    }
  }
}

.cards {
  width: 100%;
  min-height: 60vh;
  gap: 20px;
  @include flex($align: flex-start);
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 15px;
}

.center {
  margin: auto;
  margin-top: 20vh;
}

.section {
  width: 100%;
  background-color: white;
  position: relative;
}

.imgReveal {
  @include imgReveal();
}

.dividerRotated {
  position: absolute;
  width: 100%;
  height: 160px;
  top: 100%;
  svg {
    transform: rotate(180deg) !important;
  }
  z-index: 50;
}
