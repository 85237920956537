@import "../../styles/mixins";

.slider {
  height: 150px;
  margin-bottom: 40px;
  overflow-y: hidden;
}

.logo {
  height: 100px;
  width: 200px;
  margin: 0 30px;
  filter: grayscale(100);
  transition: all 1s ease;
  opacity: 0.8;
  cursor: pointer;
  @include background-image("../../assets/logos/logo.png");

  &:hover {
    filter: none;
    opacity: 1;
    scale: 1.05;
  }
}
