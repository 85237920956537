:root {
  --primary: #3861ed;
  --primary-80: #3862ed14;

  --red: #f01d26;
  --light-blue: #006cb9;
  --green: #8ec33b;
  --dark-green: #046844;
  --yellow: #ddd200;
  --orange: #f5811d;
  --dark-blue: #00aadc;
  --purple: #675da9;

  --text-color: #14286c;

  --viber-gradient: linear-gradient(to right, #675da9, #9d50bb);
  --gradient-to-right: linear-gradient(to right, #3861ed, #00aadc);

  --blue-shadow: #3861ed 0px 1px 3px 0px, #3861ed 0px 0px 0px 1px;
  --card-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgb(209, 213, 219) 0px 0px 0px 1px inset;
}
