@import "../../styles/mixins";

.container {
  width: 350px;
  height: 520px;
  min-height: 520px;

  text-decoration: none;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding-bottom: 20px;
  cursor: pointer;

  @include flex($direction: column, $justify: flex-start);
  background-color: white;
  transition: all 0.5s ease;

  &:hover {
    box-shadow: #3862ed73 0px 1px 3px 0px, #3861ed73 0px 0px 0px 1px;
  }
}

.image {
  width: 100%;
  height: 40%;
  // max-height: 40%;
  -webkit-transform: translate3d(0, 0, 0);
  background-position: center;
  background-size: cover;

  background-repeat: no-repeat;
  border-radius: 10px 10px 0 0;
  opacity: 0.8;
  transition: all 0.5s ease;
  border-bottom: 1px solid rgba(99, 99, 99, 0.2);
  z-index: 50;
}

.type {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 500;
  position: absolute;
  top: -15px;
  right: 15px;
  z-index: 60;
  width: fit-content;
  padding: 9px;
  border-radius: 30px;
}

.content {
  width: 100%;
  max-height: 55%;
  @include flex($direction: column, $align: flex-start, $justify: flex-start);
  padding: 25px 35px 0px 35px;
  border-radius: 0 0 10px 10px;
  position: relative;

  .title {
    font-size: 19px;
    margin: 0;
    color: #3a3755;
  }

  .summary {
    font-size: 15px;
    color: #828386;
    min-height: 100px;
    flex-grow: 1;
    text-align: justify;
    width: 100%;

    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.more {
  width: 100%;
  height: 30px;
  margin-top: auto;
  @include flex($justify: space-between);
  padding: 0 35px;
  color: var(--primary);
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;

  .moreText {
    transition: all 0.3s ease;
    margin-left: auto;
    &:hover {
      scale: 1.1;
    }
  }

  .inactive {
    color: var(--red);
    opacity: 0.6;
    font-weight: bolder;
  }
}

.arrow {
  height: 100%;
  width: 30px;
}
