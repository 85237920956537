.note {
  width: 100%;
  position: relative;
  // letter-spacing: 0.9px;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  gap: 10px;

  h1 {
    font-size: 24px;
  }

  p {
    width: 30%;
    text-align: center;
    @media only screen and (max-width: 600px) {
      & {
        width: 80%;
        font-size: 14px;
      }
    }
  }
}
