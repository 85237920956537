@import "../../styles/mixins";

.container {
  width: 100vw;
  @include flex($direction: column);
  align-items: center;
  position: relative;
  bottom: 0;
  top: 42%;
}

.content {
  width: 85%;
  padding: 20px 0;
  @include flex($justify: flex-start, $align: flex-start);

  @media only screen and (max-width: "1000px") {
    flex-direction: column;

    .section {
      width: 100%;
      padding: 10px;

      justify-content: center;
      align-items: center;
      border-bottom: 1px solid rgba($color: #fff, $alpha: 0.3);
    }

    .section:last-child {
      border-bottom: none;
    }

    .contact {
      text-align: center;
    }
  }
}

.section {
  height: 100%;

  p {
    color: #686781;
    color: white;
    letter-spacing: 0.5px;
    margin: 1px;
  }
}

.info {
  width: 35%;
  @include flex($direction: column, $align: flex-start, $justify: flex-start);
  gap: 10px;
  font-size: 15px;

  @media only screen and (max-width: "1000px") {
    width: 100%;
    justify-content: center;
    text-align: center;

    .logo {
      background-position: center;
    }
  }
}

.links {
  flex-grow: 1;
  height: 230px;

  padding: 15px;
  @include flex($direction: column, $justify: center, $align: space-around);
  flex-wrap: wrap;
  gap: 10px;
  text-align: left;
  font-size: 13px;

  @media only screen and (max-width: "1000px") {
    width: 100%;
    align-items: center;
    padding: 15px 0;
    height: 60vh !important;
    order: 2;
  }
}

.link {
  text-decoration: none;
  font-weight: 400;
  letter-spacing: 1.3px;
  font-size: 14px;
  color: white;

  &:hover {
    color: #0c0c1a;
  }
}

.contact {
  width: 20%;
  padding: 15px;
  @include flex($direction: column, $justify: center, $align: flex-start);
  gap: 10px;
  font-size: 15px;
  margin-top: 20px !important;

  @media only screen and (max-width: 800px) {
    & p {
      text-align: center;
    }
  }
}

.logo {
  height: 100px;
  background-position: left;

  @media only screen and (max-width: "1000px") {
    height: 80px;
  }
}

.copyright {
  display: flex;
  margin: auto;
  font-size: 14px;
  width: 80%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;

  p {
    color: rgba($color: #686781, $alpha: 0.6);
    color: white;
  }

  @media only screen and (max-width: "1000px") {
    margin-bottom: 10vh;
  }

  @media only screen and (max-width: "600px") {
    padding-bottom: 80px;
  }
}

.social {
  height: 10vh;
  min-height: 10vh;
  width: 85%;
  @include flex($justify: space-between, $align: center);
  border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
  border-top: 1px solid rgba($color: #fff, $alpha: 0.3);
}

.socialMedia {
  width: 100%;
  height: 40px;
  @include flex($justify: flex-start);
  margin-top: 10px;
  gap: 20px;

  a {
    width: 40px;
    height: 85%;
  }

  @media only screen and (max-width: "1000px") {
    justify-content: center;
  }
}

.facebook {
  width: 100%;
  min-height: 30px;
  @include background-image("../../assets/icons/facebook.png");
}

.instagram {
  width: 100%;
  min-height: 30px;
  @include background-image("../../assets/icons/instagram.png");
}

.linkedin {
  width: 100%;
  min-height: 30px;
  @include background-image("../../assets/icons/linkedin.png");
}

.twitter {
  width: 100%;
  min-height: 30px;
  @include background-image("../../assets/icons/twitter.png");
}

.youtube {
  width: 100%;
  min-height: 30px;
  @include background-image("../../assets/icons/youtube.png");
}
