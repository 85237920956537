@import "../../styles/mixins";

.container {
  width: 100%;
  min-height: 90vh;

  @include flex($direction: column, $justify: flex-start, $align: flex-start);
}

.searchSection {
  background-color: #e2e8fd80;
  width: 100%;
  height: 50vh;
  position: relative;
}

.searchText {
  margin: 0 auto;
  position: relative;
  text-align: center;
  font-size: 26px;
  top: 10vh;

  @media only screen and (max-width: 600px) {
    & {
      font-size: 22px;
    }
  }
}

.note {
  width: 100%;
  position: relative;
  min-height: 60vh;
  @include flex($direction: column);
  gap: 10px;
  padding-bottom: 20px;

  h1 {
    font-size: 24px;
  }

  a {
    text-decoration: none;
    color: var(--primary);
  }

  p {
    width: 50%;
    text-align: center;
    margin: 0;
    @media only screen and (max-width: 700px) {
      & {
        width: 80%;
        font-size: 14px;
      }
    }
  }
}

.search {
  top: 15vh;
}

.content {
  width: 100%;
  @include flex($direction: column, $justify: space-between);
  background-color: #e2e8fd80;
  margin-bottom: 20px;
}

.jobs {
  width: 100%;
  flex-grow: 1;
  padding: 10px;
  margin-bottom: 10vh;
  gap: 20px;
  @include flex($justify: center, $align: flex-start);
  flex-wrap: wrap;
  gap: 25px;

  @media only screen and (max-width: 600px) {
    margin-top: 5vh;
  }
}

.imgReveal {
  @include imgReveal();
}

.dividerRotated {
  position: absolute;
  width: 100%;
  height: 150px;
  top: 100%;
  svg {
    transform: rotate(180deg) !important;
  }
  z-index: 50;
}
