@import "../../styles/mixins";

.premiumJobCard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 10px auto;
  cursor: pointer;

  background-color: white;
  border: 2px solid #dbb42c;
  border-radius: 30px;

  padding: 10px;
  gap: 3px;

  height: 135px;
  min-height: 135px;
  width: 290px;
  text-decoration: none;

  .link {
    width: 100%;
    min-height: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .title {
    width: 100%;
    font-size: 15px;
    text-align: center;
    flex-grow: 1;
    max-height: 40px;
    margin: 0;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    color: black;
  }

  .tag {
    position: absolute;
    right: 0px;
    top: -10px;
    height: fit-content;
    padding: 5px 8px;

    display: flex;
    align-items: center;
    gap: 2px;

    background-color: #efe2b3;
    border-radius: 10px;
    border: 1px solid #eac43a;

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    &__text {
      font-size: 12px;
      margin: 0 !important;
      color: #dbb42c;
    }
  }

  .location {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;

    gap: 10px;
    color: black;
    margin: 0;

    .icon {
      height: 100%;
      width: 20px;
      flex-shrink: 0;
      @include background-image("../../assets/icons/pin.png");
    }

    .span {
      color: rgba($color: #14286c, $alpha: 0.8);
      font-weight: 700;
      letter-spacing: 0.2px;
      font-size: 13px;

      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;

      @media only screen and (max-width: 800px) {
        font-size: 14px;
      }
    }
  }
}
