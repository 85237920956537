@import "../../styles/mixins";

.container {
  width: 80%;
  min-height: 70px;
  @include flex($justify: flex-start);

  background-color: #fff;
  border-radius: 20px;
  box-shadow: var(--card-shadow);
  transition: all 0.4s ease-in-out;
  cursor: pointer;

  color: black;
  text-decoration: none;

  &:hover {
    box-shadow: var(--blue-shadow);
    scale: 1.01;
  }

  @media only screen and (max-width: 800px) {
    & {
      width: 95%;
      @include flex($direction: column);
      padding: 15px;
      text-align: center;

      .title {
        margin: 0;
      }
    }
  }
}

.title {
  min-height: 70px;
  flex-grow: 1;
  width: 70%;
  margin: 10px;
  margin-left: 30px;
  @include flex($justify: flex-start);

  & > h2 {
    margin: 0;
    font-weight: bolder;
    font-size: 15px;
  }
}

.info {
  // height: 100%;
  min-height: 70px;
  width: 40%;
  padding: 0;
  @include flex($justify: space-around);
  @media only screen and (max-width: 600px) {
    & {
      width: 100%;
      padding-bottom: 10px;
      font-size: 14px;
    }
  }
}

.wrapper {
  width: 50%;
  @include flex($direction: column, $justify: flex-start);
}

.subtitle {
  font-size: 14px;
  color: #666;
  margin: 5px;
}

.data {
  margin: 5px 0;
  font-weight: bolder;
}
