@import "../../styles/mixins";

.cards {
  width: 100%;
  min-height: 60vh;
  gap: 20px;
  display: flex;
  @include flex();
  flex-wrap: wrap;
  margin-top: 15px;
  margin-bottom: 15vh;
}

.section {
  width: 100%;
  height: 40vh;
  background-color: white;
  flex-grow: 1;
  @include flex($direction: column);
  margin-top: auto;

  a {
    font-size: 22px;
    color: black;
    color: #182b46;

    text-decoration: none;
    font-weight: 600;
    @include flex();
    gap: 10px;
    transition: all 0.5s ease;

    &:hover {
      color: var(--primary);
    }

    @media only screen and (max-width: 600px) {
      & {
        width: 75%;
        flex-wrap: wrap;
        text-align: center;
      }
    }
  }
}

.templates {
  width: 50%;
  height: 30%;
  margin: 20px 0;
  @include flex($justify: space-around);
  gap: 20px;
  flex-wrap: wrap;
}
