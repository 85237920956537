.container {
  min-width: 300px;
  width: 500px;
  height: 60%;
  padding: 20px;
  border-radius: 20px;

  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgb(209, 213, 219) 0px 0px 0px 1px inset;
  background-color: white;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 100%;
  height: 40px;
  cursor: pointer;
  background-image: url("../../assets/logos/logo.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.form {
  flex-grow: 1;
  margin-top: 20px;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 25px;
}

.btn {
  width: 70%;
  margin-top: 20px;
}
