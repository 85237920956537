.layoutContainer {
  width: 100%;
  min-height: 100vh;
}

.headerContainer {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.wrapper {
  display: flex;
  position: relative;
}

.contentContainer {
  flex-grow: 5;
  height: calc(100vh - 3rem);
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: rgba($color: #3861ed, $alpha: 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background: rgba($color: #3861ed, $alpha: 0.8);
    border-radius: 50px;
  }
}

.footerContainer {
  width: 100%;
  height: 60px;
}
