@import "../../../styles/mixins";

.container {
  width: 85%;
  display: flex;
  padding: 10px;
  cursor: pointer;
  text-decoration: none;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.5);

  color: var(--text-color);
  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }

  .img {
    width: 40%;
    height: 300px !important;
    background-position: center !important;
    flex-shrink: 0;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    background-color: white !important;
    -webkit-transform: translate3d(0, 0, 0);

    @media only screen and (max-width: 800px) {
      width: 100%;
      height: 250px;
    }
  }

  .content {
    padding: 0 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    h1 {
      margin: 10px;
      font-size: 26px !important;
      color: var(--text-color);
      text-align: left;
      font-size: 32px;
    }

    .date {
      color: #8892b5;
    }

    p {
      text-align: justify;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .actions {
      position: relative;
      bottom: 0;
      width: 100%;
      height: 50px;
      margin-top: auto;
      margin-bottom: 5px;
      padding: 0 7px;

      @include flex($justify: flex-end, $align: flex-end);
      gap: 10px;

      color: var(--text-color);

      transition: all 0.3s ease;

      &:hover {
        gap: 15px;
        padding: 3px;
        color: var(--primary);
      }
    }
  }
}
