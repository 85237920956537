@import "../../../styles/mixins";

%wrapper {
  height: 100%;
  @include flex();
  justify-content: space-between;
  margin: 0 10px;
  padding-right: 10px;
}

.container {
  width: 100%;
  @include flex($direction: column, $justify: flex-start, $align: center);
  padding: 20px;
  gap: 30px;
}

.content {
  width: 100%;
  height: 85vh;
  overflow-y: auto;
  @include flex($justify: center, $align: flex-start);
  gap: 15px;
  flex-wrap: wrap;
}

.card {
  width: 350px;
  height: 100px;
  background-color: white;
  @include flex($justify: flex-start);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  cursor: pointer;
  border-radius: 20px;
  padding: 5px;

  text-align: center;
  gap: 10px;

  img {
    height: 90%;
    width: 40%;
    flex-shrink: 0;
    object-fit: center;
    object-fit: contain;
  }
}

.selected {
  box-shadow: var(--blue-shadow);
}

.search {
  @extend %wrapper;
  width: 60%;
  border-right: 1px solid rgba(0, 0, 0, 0.09);
  background-color: white;
  padding: 10px;
  border-radius: 70px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.input {
  height: 40px;
  width: 100%;
  border: none;
  @include flex();
  flex-direction: space-between;
}

.clear {
  min-width: 12px;
  height: 12px;
  margin-right: 5px;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  @include background-image("../../../assets/icons/clear.svg");
}

.show {
  opacity: 1;
}

.hide {
  opacity: 0;
}
