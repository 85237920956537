@import "../../../styles/mixins";

.jobs {
  width: 100%;
  min-height: 100vh;
  flex-grow: 1;
  padding: 10px;
  margin-bottom: 10vh;
  gap: 20px;
  @include flex($justify: center, $align: flex-start);
  flex-wrap: wrap;
  gap: 25px;
}
