@import "../../styles/mixins";

.wrapper {
  @include flex($direction: column);
  transition: all 0.5s ease;

  position: relative;
}

.text {
  width: auto;
  text-align: center;
  font-weight: 500;
  @include flex();
  gap: 10px;
  cursor: pointer;
}

.icon {
  width: 10px;
  height: 10px;
  transition: all 0.5s ease;
}

.container {
  width: 300px;
  padding: 20px;
  background-color: #fff;
  transition: all 0.5s ease;
  position: absolute;
  top: 50px;
  z-index: 500;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  border-radius: 10px;
  @include flex($direction: column, $justify: center, $align: center);
}

.display {
  display: block;
}

.hidden {
  display: none;
}

.arrowUp {
  @include background-image("../../assets/icons/dropdown-arrow.png");
}

.arrowDown {
  @include background-image("../../assets/icons/dropdown-arrow.png");
  transform: rotate(180deg);
}
