@import "../../styles/mixins";

%summary {
  width: 100%;
  height: 50%;
  @include flex($justify: flex-start);
  gap: 10px;

  @media only screen and (max-width: 800px) {
    font-size: 14px;
  }
}

.container {
  height: 320px;
  width: 350px;
  border-radius: 30px;
  background-color: white;
  position: relative;
  text-decoration: none;
  color: #000;

  box-shadow: var(--card-shadow);

  @include flex($direction: column, $justify: flex-start);

  padding-bottom: 0;
  cursor: pointer;

  transition: all 0.8s ease-in-out;

  &:hover {
    box-shadow: var(--blue-shadow);

    .action {
      background: var(--primary);
    }
    .button {
      color: white;
    }
  }
}

.expired {
  border: 1px solid var(--red);

  &:hover {
    box-shadow: none !important;
    border: 1px solid var(--red) !important;

    .action {
      background: var(--red);
    }
    .button {
      color: white;
    }
  }
}

.izdvojeni {
  border: 1px solid var(--green);

  &:hover {
    box-shadow: none !important;
    border: 1px solid var(--green);

    .action {
      background: var(--green);
    }
    .button {
      color: white;
    }
  }
}

.premium {
  border: 1px solid #eac43a;
  background: linear-gradient(to bottom, #fff, #faf7ebec);

  &:hover {
    box-shadow: none !important;
    border: 1px solid #eac43a;

    .action {
      background: #eac43a;
    }
    .button {
      color: white;
    }
  }
}

.tag {
  position: absolute;
  right: 5px;
  top: -7px;

  height: 30px;
  width: 120px;
  // z-index: 200;

  text-align: center;
  color: white;

  border-radius: 10px;
  background-color: var(--red);

  @include flex();

  p {
    margin: 0;
    font-size: 14px;
    line-height: 14px;
  }
}

.link {
  width: 70%;
  height: 25%;
  margin: 10px 0 0 0;
}

.logo {
  width: 100%;
  height: 100%;
  @include background-image("../../assets/logos/logo.png");
}

.content {
  flex-grow: 1;
  width: 90%;
  gap: 10px;
  margin-bottom: 10px;
  @include flex($direction: column, $justify: space-between);
}

.title {
  width: 100%;
  font-size: 17px;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.info {
  width: 90%;
  height: 40%;
}

.location {
  @extend %summary;

  .icon {
    height: 50%;
    width: 20px;
    flex-shrink: 0;
    @include background-image("../../assets/icons/pin.png");
  }
}

.dueDate {
  @extend %summary;

  .icon {
    height: 50%;
    width: 20px;
    @include background-image("../../assets/icons/time.png");
  }
}

.span {
  color: rgba($color: #14286c, $alpha: 0.8);
  font-weight: 700;
  letter-spacing: 0.2px;
  font-size: 15px;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media only screen and (max-width: 800px) {
    font-size: 14px;
  }
}

.action {
  width: 100%;
  height: 20%;
  border-top: 1px solid rgb(209 213 219);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  @include flex();
  margin-top: auto;
  transition: all 0.8s ease;

  border-radius: 0 0 30px 30px;
}

.button {
  font-size: 18px;
  letter-spacing: 0.5px;
  color: var(--primary);
  font-weight: bolder;
}
