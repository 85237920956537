.button {
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  border-radius: 82px;
  width: 352px;
  height: 48px;
  border: none;
  cursor: pointer;
  padding: 5px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  transition: all 0.5s ease;
}

.primary {
  background: var(--primary);
  background: linear-gradient(to right, #3861ed, #00aadc);
  transition: outline-offset 0.3s ease;

  &:hover {
    outline: 2px solid var(--primary);
    outline-offset: 3px;
  }
}

.default {
  color: var(--primary);
  background-color: transparent;
  border: 1px solid var(--primary);
  transition: all 0.4s ease;

  &:hover {
    background-color: #3862ed11;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 50px 40px -7px;
  }
}

.link {
  color: var(--primary);
  font-weight: 400;
  font-size: 14px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}
