@import "../../styles/mixins";

%arrow {
  height: 35px;
  width: 35px;
  @include background-image("../../assets/icons/arrow-circle.svg");
  position: absolute;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 10;

  &:hover {
    scale: 1.1;
  }
}

%box {
  position: absolute;
  width: 340px;
  height: 320px;
  border-radius: 30px;
}

.container {
  height: 320px;
  width: 340px;
  position: relative;
  // z-index: 100;
  margin-right: 10px;

  @media only screen and (max-width: 500px) {
    margin-right: 0;
  }
}

.lightShadow {
  @extend %box;
  background-color: rgba($color: #3861ed, $alpha: 0.43);
  left: 7px;
  bottom: 6px;
  z-index: 3;

  @media only screen and (max-width: 500px) {
    left: 2px;
    width: 335px;
  }
}

.darkShadow {
  @extend %box;
  background-color: rgba($color: #8ba2f1, $alpha: 0.43);
  left: 13px;
  bottom: 10px;
  z-index: 2;

  @media only screen and (max-width: 500px) {
    left: 3px;
    width: 340px;
  }
}

.tag {
  position: absolute;
  right: 5px;
  top: -7px;

  height: 30px;
  width: 120px;
  z-index: 50;

  text-align: center;
  color: white;

  border-radius: 10px;
  background: var(--primary);

  @include flex();

  p {
    margin: 0;
    font-size: 14px;
    line-height: 14px;
  }
}

.card {
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 10;
  position: relative;

  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgb(209, 213, 219) 0px 0px 0px 1px inset;

  border-radius: 30px;
  padding: 0 10px;
  @include flex();
}

.arrowLeft {
  @extend %arrow;
  left: 3px;
}

.arrowRight {
  @extend %arrow;
  right: 3px;
  @include background-image("../../assets/icons/arrow-circle.svg");
  transform: rotate(180deg);
}

.cardWrapper {
  flex-grow: 1;
  height: 100%;
}

.konkurs {
  width: 100%;
}

.button {
  width: 340px;
}
