.blogsView {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
  height: 100%;
  overflow-x: hidden;

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    h1 {
      margin: 0;
      color: var(--text-color);
      text-align: left;
      font-size: 24px;
      width: 100%;
    }

    .newBlogButton {
      width: 180px;
    }
  }

  .categories {
    display: flex;
    width: 90%;
    gap: 15px;
    justify-content: space-evenly;
    margin-bottom: 25px;

    li {
      list-style: none;
      cursor: pointer;
      transition: all 0.3s linear;
      text-align: center;

      &:hover {
        color: var(--primary);
      }
    }
  }

  .blogs {
    width: 100%;
    margin-top: 10px;
    padding-bottom: 20px;
    display: flex;
    overflow-y: scroll;
    flex-wrap: wrap;
    gap: 15px;
    overflow-x: hidden;
  }
}

.active {
  color: var(--primary) !important;
  font-weight: bolder;
}
