@import "../../../../styles//mixins";

.container {
  position: absolute;
  width: 60%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 500px;

  border-radius: 10px;
  background-color: white;

  padding: 15px;
  @include flex($direction: column);

  gap: 10px;

  .title {
    margin: 20px;
    color: var(--text-color);
    font-size: 18px;
    font-weight: bolder;
  }
}

.upload {
  width: 90%;
  height: 60%;
  padding: 10px 0;
  // height: 90px;
  border: 1px dashed #00aadc;
  @include flex($direction: column);
  border-radius: 5px;
}
