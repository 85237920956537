.premiumJobs {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 165px;
  padding: 10px 25px;

  animation: 1s alternate 0s 1 slideIn;

  background: #14286c;
  background: linear-gradient(to right, #3861edcc, #00aadccc);
}

@keyframes slideIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
